import * as Yup from 'yup';
export var DeparetementWorktimeValidator = Yup.object().shape({
    year: Yup.date().required('Year is required').typeError('Invalid year'),
    month: Yup.date().required('Month is required').typeError('Invalid month'),
    startHour: Yup.date().required('Start hour is required').typeError('Invalid start hour'),
    endHour: Yup.date()
        .required('End hour is required')
        .typeError('Invalid end hour')
        .min(Yup.ref('startHour'), 'End hour must be after start hour'),
});
