import * as Yup from 'yup';
export var bookSaleTransactionValidationForSuperAdmin = Yup.object().shape({
    note: Yup.string().max(200).optional(),
    office: Yup.object().required('Office is required'),
    items: Yup.array().of(Yup.object().shape({
        book: Yup.object().required(),
        quantitySold: Yup.number().integer().min(0).required(),
        salePrice: Yup.number().required(),
    })),
});
